<template>
  <div>
    <!-- Page Header Start -->
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item
            :to="{
              name: 'DashboardHome',
            }"
          >
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            銷售應用中心
          </b-breadcrumb-item>

          <b-breadcrumb-item :to="{ name: 'PreorderSettingList' }">
            預購單設定檔列表
          </b-breadcrumb-item>

          <b-breadcrumb-item active
            >{{
              isEditing ? '編輯預購單設定檔' : '新增預購單設定檔'
            }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <!-- Page Header End -->

    <!-- Form Start -->
    <div class="email-wrapper wrapper">
      <div class="row bg-white">
        <div class="col-md-2 mail-sidebar pt-3">
          <div class="menu-bar">
            <ul class="menu-items">
              <li v-if="checkPermission([consts.PREORDER_SETTING_MODIFY])" :class="{ active: step == '', done: preorderSetting.id }" @click="goToStep('')">
                <a>基本設定</a>
                <i class="fa fa-check-circle icon-for-done" aria-hidden="true"></i>
                <i class="fa fa-check-circle-o icon-for-undone " aria-hidden="true"></i>
              </li>
              <li v-if="checkPermission([consts.PREORDER_SETTING_ADVANCE])" :class="{ active: step == '#fields', done: preorderSetting.config.fields && preorderSetting.config.fields.length > 0, disabled: !preorderSetting.id }" @click="goToStep('#fields')">
                <a>資料集欄位</a>
                <i class="fa fa-check-circle icon-for-done" aria-hidden="true"></i>
                <i class="fa fa-check-circle-o icon-for-undone " aria-hidden="true"></i>
              </li>
              <li v-if="checkPermission([consts.PREORDER_SETTING_MODIFY])" :class="{ active: step == '#product-setting', disabled: !preorderSetting.id }" @click="goToStep('#product-setting')">
                <a>商品設定</a>
              </li>
              <li v-if="checkPermission([consts.PREORDER_SETTING_MODIFY])" :class="{ active: step == '#welcome', disabled: !preorderSetting.id }" @click="goToStep('#welcome')">
                <a>歡迎頁</a>
              </li>
              <li v-if="checkPermission([consts.PREORDER_SETTING_MODIFY])" :class="{ active: step == '#confirm', disabled: !preorderSetting.id }" @click="goToStep('#confirm')">
                <a>確認頁</a>
              </li>
              <li v-if="checkPermission([consts.PREORDER_SETTING_MODIFY])" :class="{ active: step == '#finish', disabled: !preorderSetting.id }" @click="goToStep('#finish')">
                <a>完成頁</a>
              </li>
              <li v-if="checkPermission([consts.PREORDER_SETTING_ADVANCE])" :class="{ active: step == '#advance-setting', disabled: !preorderSetting.id }" @click="goToStep('#advance-setting')">
                <a>進階設定</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-10 main-view">
          <b-overlay :show="showLoading">
            <b-card>
              <!-- 基本資料 section start -->
              <section v-if="step == ''">
                <!-- 欄位部分 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">{{ isEditing ? '編輯預購單設定檔' : '新增預購單設定檔' }}</h4>
                  <div class="col-12 col-xl-8">
                    <validation-error-alert
                      v-if="validationErrors"
                      :errors="validationErrors"
                    ></validation-error-alert>
                    <!-- 名稱 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="*預購單活動名稱"
                    >
                      <b-form-input
                        class="mb-2 mr-sm-2 mb-sm-0"
                        type="text"
                        :state="v$.preorderSetting.title.$error ? false: null"
                        v-model="preorderSetting.title"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="!v$.preorderSetting.title.$error">
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 名稱 End -->

                    <!-- 開始時間 start_at -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="開始時間"
                      label-for="start_at"
                    >
                      <div class="row">
                        <div class="col-12 col-xl-6">
                          <datepicker
                            placeholder="Select Date"
                            v-model="start_at.date"
                            bootstrap-styling
                            format="yyyy-MM-dd"
                            :language="zh"
                            :input-class="v$.start_at.date.$error ? 'is-invalid' : ''"
                          ></datepicker>
                          <b-form-invalid-feedback :state="!v$.start_at.date.$error">
                            此欄位為必填
                          </b-form-invalid-feedback>
                        </div>

                        <div class="col-12 col-xl-6">
                          <vue-timepicker
                            format="HH:mm"
                            v-model="start_at.time"
                            :input-class="[
                              'form-control'
                            ]"
                            :style="v$.start_at.time.HH.$error || v$.start_at.time.mm.$error ? 'border: 1px solid red' : ''"
                          ></vue-timepicker>
                          <b-form-invalid-feedback :state="!v$.start_at.time.HH.$error || !v$.start_at.time.mm.$error">
                            此欄位為必填
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </b-form-group>
                    <!-- 開始時間 end -->

                    <!-- 結束時間 end_at -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="結束時間"
                      label-for="end_at"
                    >
                      <div class="row">
                        <div class="col-12 col-xl-6">
                          <datepicker
                            placeholder="Select Date"
                            v-model="end_at.date"
                            bootstrap-styling
                            format="yyyy-MM-dd"
                            :language="zh"
                            :input-class="v$.end_at.date.$error ? 'is-invalid' : ''"
                          ></datepicker>
                          <b-form-invalid-feedback :state="!v$.end_at.date.$error">
                            此欄位為必填
                          </b-form-invalid-feedback>
                        </div>

                        <div class="col-12 col-xl-6">
                          <vue-timepicker
                            format="HH:mm"
                            v-model="end_at.time"
                            :input-class="[
                              'form-control'
                            ]"
                            :style="v$.end_at.time.HH.$error || v$.end_at.time.mm.$error ? 'border: 1px solid red' : ''"
                          ></vue-timepicker>
                          <b-form-invalid-feedback :state="!v$.end_at.time.HH.$error || !v$.end_at.time.mm.$error">
                            此欄位為必填
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </b-form-group>
                    <!-- 結束時間 -->

                    <!-- 啟用 start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="是否啟用"
                    >
                      <b-form-checkbox v-model="preorderSetting.is_enabled">啟用</b-form-checkbox>
                    </b-form-group>
                    <!-- 啟用 end -->

                    <!-- 商品是否使用庫存 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="商品是否使用庫存"
                    >
                      <b-form-checkbox v-model="preorderSetting.config.product_page.check_stock" :value="true" :unchecked-value="false"></b-form-checkbox>
                    </b-form-group>
                    <!-- 商品是否使用庫存 End -->

                    <!-- 前台是否顯示金額 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="前台是否顯示金額"
                    >
                      <b-form-checkbox v-model="preorderSetting.config.product_page.display_price" :value="true" :unchecked-value="false"></b-form-checkbox>
                    </b-form-group>
                    <!-- 前台是否顯示金額 End -->

                    <!-- 填寫權限 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="填寫權限"
                    >
                      <b-form-checkbox-group
                        v-model="preorderSetting.config.permissions"
                        :options="permissionOptions"
                      ></b-form-checkbox-group>
                    </b-form-group>
                    <!-- 填寫權限 End -->

                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 基本資料 section end -->

              <!-- 資料集欄位設定 section start -->
              <section v-if="step == '#fields'">
                <NameBlock :label="'預購單活動名稱'" :name="preorderSetting.title" />
                <div>
                  <DynamicFormEditor
                    v-if="preorderSetting.id"
                    @output="saveFields"
                    editor-title="預約單欄位編輯"
                    :form-type="'form'"
                    :input-subjects="preorderSetting.config.fields"
                  ></DynamicFormEditor>
                </div>
              </section>
              <!-- 資料集欄位設定 section end -->

              <!-- 商品設定 section start -->
              <section v-if="step == '#product-setting'">
                <NameBlock :label="'預購單活動名稱'" :name="preorderSetting.title" />
                <!-- 標題 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">商品設定</h4>
                </div>
                <!-- 標題 End -->

                <!-- 商品設定按鈕區 start -->
                <div class="d-flex align-items-center justify-content-between mb-3">
                  <b-button
                    class="flex-shrink-0 mr-2"
                    variant="inverse-primary"
                    v-b-modal.modal-search-product
                    :disabled="showLoading"
                  ><i class="fa fa-plus"></i>選擇商品</b-button>
                  <!-- <b-button
                    @click="saveProducts"
                    class="flex-shrink-0"
                    variant="success"
                    :disabled="showLoading"
                  >儲存</b-button> -->
                </div>
                <!-- 商品設定按鈕區 end -->

                <!-- 商品選擇視窗 start -->
                <PreorderSettingProductSelect
                  :selected-products="selectedProducts"
                  @selected="handleUpdateSelectedProducts"
                />
                <!-- 商品選擇視窗 end -->

                <!-- 商品清單 Start -->
                <b-table striped hover responsive :items="products" :fields="productFields">
                  <template #cell(title)="data">
                    <router-link :to="{ name: 'ProductDetail', params: { product_id: data.item.id } }">
                      <div>{{ deepGet(data.item, 'title') }}</div>
                      <div>{{ deepGet(data.item, 'sale_page_code') }}</div>
                    </router-link>
                  </template>
                  <template #cell(image)="data">
                    <b-img-lazy
                      v-if="deepGet(data.item, 'images[0].pic_url')"
                      class="thumb-image"
                      :src="deepGet(data.item, 'images[0].pic_url')"
                      alt="商品圖片"
                      width="50"
                      height="50"
                    />
                  </template>
                  <template #cell(actions)="data">
                    <b-button
                      class="mr-1"
                      variant="inverse-danger"
                      @click="deleteProduct(data.item, data)"
                    >
                      <span class="mdi mdi-delete"></span> 移除
                    </b-button>
                  </template>
                </b-table>
                <!-- 商品清單 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 預約設定 section end -->

              <!-- 歡迎 section start -->
              <section v-if="step == '#welcome'">
                <NameBlock :label="'預購單活動名稱'" :name="preorderSetting.title" />
                <!-- 標題 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">歡迎頁</h4>
                </div>
                <!-- 標題 End -->

                <!-- 歡迎頁面頁面說明 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="歡迎頁面頁面說明"
                >
                  <TrixEditor v-model="preorderSetting.config.welcome_page.terms" />
                </b-form-group>
                <!-- 歡迎頁面頁面說明 End -->
                <!-- 歡迎頁面同意文字 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="歡迎頁面同意文字"
                >
                  <b-form-input
                    type="text"
                    v-model="preorderSetting.config.welcome_page.hint_for_consent"
                    style="width: 200px"
                  ></b-form-input>
                </b-form-group>
                <!-- 歡迎頁面同意文字 End -->
                <!-- 歡迎頁面按鈕文字 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="歡迎頁面按鈕文字"
                >
                  <b-form-input
                    type="text"
                    v-model="preorderSetting.config.welcome_page.button_text"
                    style="width: 200px"
                  ></b-form-input>
                </b-form-group>
                <!-- 歡迎頁面按鈕文字 End -->

                <!-- 分店選單圖片 end -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="分店選單圖片(上方)"
                >
                  <button class="btn btn-link px-2 text-dark align-self-start upload-button">
                    <b-form-file
                      @change="upload($event, 'welcome_page.top_image_url')"
                      placeholder="尚未選擇檔案" browse-text="瀏覽"
                    />
                    <a
                      v-if="!!preorderSetting.config['welcome_page']['top_image_url']"
                      @click="preorderSetting.config['welcome_page']['top_image_url'] = null"
                      class="btn-sm btn-outline-danger"
                    >
                      <i class="fa fa-trash-o"></i>
                    </a>
                    <a :href="preorderSetting.config['welcome_page'] ? preorderSetting.config['welcome_page']['top_image_url'] : null" target="_blank">
                      <small> 目前路徑：{{preorderSetting.config['welcome_page']['top_image_url'] || '未上傳'}} </small>
                    </a>
                  </button>
                </b-form-group>
                <!-- 分店選單圖片 end -->

                <!-- 分店選單圖片 start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="分店選單圖片(下方)"
                >
                  <button class="btn btn-link px-2 text-dark align-self-start upload-button">
                    <b-form-file
                      @change="upload($event, 'welcome_page.bottom_image_url')"
                      placeholder="尚未選擇檔案" browse-text="瀏覽"
                    />
                    <a
                      v-if="!!preorderSetting.config['welcome_page']['bottom_image_url']"
                      @click="preorderSetting.config['welcome_page']['bottom_image_url'] = null"
                      class="btn-sm btn-outline-danger"
                    >
                      <i class="fa fa-trash-o"></i>
                    </a>
                    <a :href="preorderSetting.config['welcome_page'] ? preorderSetting.config['welcome_page']['bottom_image_url'] : null" target="_blank">
                      <small> 目前路徑：{{preorderSetting.config['welcome_page']['bottom_image_url'] || '未上傳'}} </small>
                    </a>
                  </button>
                </b-form-group>
                <!-- 分店選單圖片 end -->

                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>

              <!-- 歡迎 section end -->

              <!-- 確認 section start -->
              <section v-if="step == '#confirm'">
                <NameBlock :label="'預購單活動名稱'" :name="preorderSetting.title" />
                <!-- 標題 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">確認頁</h4>
                </div>
                <!-- 標題 End -->

                <!-- 確認頁面頁面說明 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="確認頁面頁面說明"
                >
                  <TrixEditor v-model="preorderSetting.config.confirm_page.terms" />
                </b-form-group>
                <!-- 確認頁面頁面說明 End -->

                <!-- 分店選單圖片 end -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="分店選單圖片(上方)"
                >
                  <button class="btn btn-link px-2 text-dark align-self-start upload-button">
                    <b-form-file
                      @change="upload($event, 'confirm_page.top_image_url')"
                      placeholder="尚未選擇檔案" browse-text="瀏覽"
                    />
                    <a
                      v-if="!!preorderSetting.config['confirm_page']['top_image_url']"
                      @click="preorderSetting.config['confirm_page']['top_image_url'] = null"
                      class="btn-sm btn-outline-danger"
                    >
                      <i class="fa fa-trash-o"></i>
                    </a>
                    <a :href="preorderSetting.config['confirm_page'] ? preorderSetting.config['confirm_page']['top_image_url'] : null" target="_blank">
                      <small> 目前路徑：{{preorderSetting.config['confirm_page']['top_image_url'] || '未上傳'}} </small>
                    </a>
                  </button>
                </b-form-group>
                <!-- 分店選單圖片 end -->

                <!-- 分店選單圖片 start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="分店選單圖片(下方)"
                >
                  <button class="btn btn-link px-2 text-dark align-self-start upload-button">
                    <b-form-file
                      @change="upload($event, 'confirm_page.bottom_image_url')"
                      placeholder="尚未選擇檔案" browse-text="瀏覽"
                    />
                    <a
                      v-if="!!preorderSetting.config['confirm_page']['bottom_image_url']"
                      @click="preorderSetting.config['confirm_page']['bottom_image_url'] = null"
                      class="btn-sm btn-outline-danger"
                    >
                      <i class="fa fa-trash-o"></i>
                    </a>
                    <a :href="preorderSetting.config['confirm_page'] ? preorderSetting.config['confirm_page']['bottom_image_url'] : null" target="_blank">
                      <small> 目前路徑：{{preorderSetting.config['confirm_page']['bottom_image_url'] || '未上傳'}} </small>
                    </a>
                  </button>
                </b-form-group>
                <!-- 分店選單圖片 end -->

                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 確認 section end -->

              <!-- 完成 section start -->
              <section v-if="step == '#finish'">
                <NameBlock :label="'預購單活動名稱'" :name="preorderSetting.title" />
                <!-- 標題 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">完成頁</h4>
                </div>
                <!-- 標題 End -->

                <!-- 結束頁面標題 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="結束頁面標題"
                >
                  <b-form-input
                    type="text"
                    v-model="preorderSetting.config.finish_page.form_title"
                    style="width: 200px"
                  ></b-form-input>
                </b-form-group>
                <!-- 結束頁面標題 End -->
                <!-- 結束頁面副標題 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="結束頁面副標題"
                >
                  <b-form-input
                    type="text"
                    v-model="preorderSetting.config.finish_page.form_desc"
                    style="width: 200px"
                  ></b-form-input>
                </b-form-group>
                <!-- 結束頁面副標題 End -->
                <!-- 結束頁面敘述 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="結束頁面敘述"
                >
                  <TrixEditor v-model="preorderSetting.config.finish_page.content" />
                </b-form-group>
                <!-- 結束頁面敘述 End -->
                <!-- 結束頁面按鈕文字 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="結束頁面按鈕文字"
                >
                  <b-form-input
                    type="text"
                    v-model="preorderSetting.config.finish_page.button_text"
                    style="width: 200px"
                  ></b-form-input>
                </b-form-group>
                <!-- 結束頁面按鈕文字 End -->
                <!-- 結束頁面按鈕超連結 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="結束頁面按鈕超連結"
                >
                  <b-form-input
                    type="text"
                    v-model="preorderSetting.config.finish_page.button_url"
                    style="width: 200px"
                  ></b-form-input>
                </b-form-group>
                <!-- 結束頁面按鈕超連結 End -->
                <!-- 結束頁面按鈕動作 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="結束頁面按鈕動作"
                >
                  <b-form-input
                    type="text"
                    v-model="preorderSetting.config.finish_page.button_action"
                    style="width: 200px"
                  ></b-form-input>
                </b-form-group>
                <!-- 結束頁面按鈕動作 End -->

                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 完成 section end -->

              <!-- 進階設定 section start -->
              <section v-if="step == '#advance-setting'">
                <NameBlock :label="'預購單活動名稱'" :name="preorderSetting.title" />
                <!-- 欄位部分 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">進階設定</h4>
                </div>
                <!-- 欄位部分 End -->

                <!-- 設定部分 Start -->

                <!-- 分店選單控制 start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="是否啟用分店選單"
                  label-for="branch_select_enabled"
                >
                  <b-form-checkbox
                    id="branch_select_enabled"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="preorderSetting.config.branch_select.is_enabled"
                    name="branch_select_enabled"
                    :value="true"
                    :unchecked-value="false"
                  ></b-form-checkbox>
                </b-form-group>
                <!-- 分店選單控制 end -->

                <!-- 分店下拉選單來源 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="分店下拉選單來源"
                >
                  <b-form-input
                    type="text"
                    v-model="preorderSetting.config.branch_select.branch_provider"
                    style="width: 200px"
                  ></b-form-input>
                </b-form-group>
                <!-- 分店下拉選單來源 End -->
                <!-- 分店下拉選單文字 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="分店下拉選單文字"
                >
                  <b-form-input
                    type="text"
                    v-model="preorderSetting.config.branch_select.default_select_text"
                    style="width: 200px"
                  ></b-form-input>
                </b-form-group>
                <!-- 分店下拉選單文字 End -->
                <!-- 是否顯示分店代號 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="是否顯示分店代號"
                >
                  <b-form-checkbox v-model="preorderSetting.config.branch_select.show_branch_code" :value="true" :unchecked-value="false"></b-form-checkbox>
                </b-form-group>
                <!-- 是否顯示分店代號 End -->

                <!-- 狀態參數 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="狀態參數"
                >
                  <editor
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="statusInput"
                    width="100%"
                    height="200px"
                    @init="editorInit"
                    lang="json"
                    theme="chrome"
                  >
                  </editor>
                </b-form-group>
                <!-- 狀態參數 End -->

                <!-- 填寫後貼標 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="填寫後貼標"
                >
                  <TagsBox v-model="preorderSetting.config.finish_tags" />
                </b-form-group>
                <!-- 填寫後貼標 End -->

                <!-- 設定部分 End -->

                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 進階設定 section end -->
            </b-card>
          </b-overlay>
        </div>
      </div>
    </div>
    <!-- Form End -->

  </div>
</template>

<script>
import { zh } from "vuejs-datepicker/dist/locale";
import useVuelidate from "@vuelidate/core";
import {
  required,
  //helpers
} from "@vuelidate/validators";
// import bookingApi from "@/apis/booking";
import preorderApi from "@/apis/preorder";
// import JsonEditor from '@/components/Dashboard/JsonEditor.vue';
import DynamicFormEditor from '@/components/DynamicFormEditor/DynamicFormEditor.vue';
import TagsBox from "@/components/TagsBox";
import TrixEditor from "@/components/TrixEditor";
import mergeDefaultAndDataModel from "@/utils/mergeDefaultAndDataModel";
import deepGet from "lodash/get";
// import { invoke } from "lodash";
import { mapGetters } from "vuex";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import {format, getHours, getMinutes, set} from "date-fns";
import PreorderSettingProductSelect from "./PreorderSettingProductSelect.vue";
import fileMixin from "@/mixins/uploadFile";
import editor from 'vue2-ace-editor'
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import NameBlock from "@/pages/Dashboard/Share/NameBlock.vue";

export default {
  mixins: [fileMixin],
  components: {
    NameBlock,
    // JsonEditor,
    DynamicFormEditor,
    TagsBox,
    TrixEditor,
    Datepicker,
    VueTimepicker,
    PreorderSettingProductSelect,
    editor,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      preorderSetting: {
        title: { required },
        config: {},
      },
      start_at: {
        date: { required },
        time: {
          HH: { required },
          mm: { required },
        },
      },
      end_at: {
        date: { required },
        time: {
          HH: { required },
          mm: { required },
        },
      },
    };
  },
  computed: {
    ...mapGetters("route", ["routeQuery"]),
    collectionFieldCandidates() {
      return [
        { text: '未設定', value: null },
        ...this.preorderSetting.config.fields.map((field) => {
          return {
            text: deepGet(field, 'config.title'),
            value: deepGet(field, 'config._id')
          };
        }),
      ]
    },
  },
  data() {
    return {
      consts: consts,
      zh,
      isEditing: false,
      showLoading: false,
      validationErrors: null,
      step: '',
      start_at: {
        date: null,
        time: {
          HH: null,
          mm: null,
        },
      },
      end_at: {
        date: null,
        time: {
          HH: null,
          mm: null,
        },
      },
      preorderSetting: {
        title: null,
        start_at: null,
        end_at: null,
        is_enabled: true,
        config: {
          fields: [],
          branch_select: {
            is_enabled: false,
            branch_provider: null,
            default_select_text: null,
            show_branch_code: false,
          },
          welcome_page: {
            top_image_url: null,
            bottom_image_url: null,
            terms: null,
            hint_for_consent: null,
            button_text: '下一步',
          },
          confirm_page: {
            top_image_url: null,
            bottom_image_url: null,
            terms: null,
          },
          product_page: {
            check_stock: false,
            display_price: false,
          },
          finish_page: {
            form_title: null,
            form_desc: null,
            content: null,
            button_text: null,
            button_url: null,
            button_action: null,
          },
          status: [],
          permissions: [],
          finish_tags: [],
        },
      },
      statusInput: '',
      products: [],
      selectedProducts: [],
      productFields: [
        { key: "title", label: "商品名稱/編號" },
        { key: "image", label: "商品圖片" },
        { key: "actions", label: "管理" },
      ],
      permissionOptions: [
        { text: "需註冊", value: "registered" },
      ],
    }
  },
  mounted() {
    if (this.$route.name === "PreorderSettingEdit") {
      this.isEditing = true;
      this.preorderSetting.id = this.$route.params.id;
      this.fetchPreorderSetting();
      this.navigateToStepFromCurrentRoute();
    } else {
      this.isEditing = false;
      this.$set(this, 'preorderSetting', this.mergeDefaultAndDataModel(this.preorderSetting, null));
    }
  },
  methods: {
    deepGet,
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    mergeDefaultAndDataModel,
    presentValidationErrors(validationKey) {
      return deepGet(this.v$, validationKey + '.$errors', []).map(error => error.$message).join(', ')
    },
    cancel() {
      this.$router.push({ name: 'PreorderSettingList', query: this.routeQuery });
    },
    goToStep(step) {
      if (!this.preorderSetting.id) {
        return;
      }

      if (this.step == step) {
        return;
      }

      this.step = step
      if (this.$route.hash !== this.step) {
        this.$router.replace({ hash: this.step });
      }
    },
    navigateToStepFromCurrentRoute() {
      const anchor = this.$route.hash;
      if (anchor) {
        this.goToStep(anchor);
      }
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) return false;

      try {
        this.showLoading = true;

        this.formatDatetime("start_at");
        this.formatDatetime("end_at");

        if (this.isEditing && this.statusInput && typeof this.statusInput === 'string') {
          this.preorderSetting.config.status = JSON.parse(this.statusInput);
        }

        // 編輯表單
        if (this.isEditing) {
          await preorderApi.updatePreorderSetting(this.preorderSetting.id, this.preorderSetting);
          this.$swal("儲存成功", "", "success");
        }
        // 新增表單
        else {
          let response = await preorderApi.createPreorderSetting({ ...this.preorderSetting});
          this.preorderSetting = response.data.data;
          this.$swal("新增成功", "", "success");
          this.isEditing = true;
          this.$router.replace({ name: "PreorderSettingEdit", params: { id: response.data.data.id } })
        }

        return true;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存失敗", "error");
        return false;
      } finally {
        this.showLoading = false;
      }
    },
    async fetchPreorderSetting() {
      if (! this.preorderSetting.id) {
        this.$swal("錯誤", "無法取得讀取 ID", "error");
        return;
      }

      try {
        this.showLoading = true;
        let response = await preorderApi.getPreorderSetting(this.preorderSetting.id);
        this.$set(this, 'preorderSetting', this.mergeDefaultAndDataModel(this.preorderSetting, response.data.data));

        const { start_at, end_at } = this.preorderSetting;

        // 還原 start_at & end_at 日期時間到 data
        if (start_at) {
          this.start_at = {
            date: new Date(start_at),
            time: {
              HH: String(getHours(new Date(start_at))).padStart(2, "0"),
              mm: String(getMinutes(new Date(start_at))).padStart(2, "0"),
            },
          };
        }
        if (end_at) {
          this.end_at = {
            date: new Date(end_at),
            time: {
              HH: String(getHours(new Date(end_at))).padStart(2, "0"),
              mm: String(getMinutes(new Date(end_at))).padStart(2, "0"),
            },
          };
        }

        if (this.preorderSetting.config.status) {
          this.statusInput = JSON.stringify(this.preorderSetting.config.status, null, 2);
        }

        this.fetchProducts();
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料錯誤", "error");
        this.cancel();
      } finally {
        this.showLoading = false;
      }
    },
    async saveFields(fields) {
      this.preorderSetting.config.fields = fields;
      this.doUpdate();
    },
    formatDatetime(key) {
      if (!this[key].date || !this[key].time.HH || !this[key].time.mm) return;

      this.preorderSetting[key] = format(
        set(new Date(this[key].date), {
          hours: Number(this[key].time.HH),
          minutes: Number(this[key].time.mm),
          seconds: 0,
        }),
        "yyyy-MM-dd HH:mm:ss"
      );
    },
    async saveProducts() {
      this.doUpdate();
    },
    async doUpdate() {
      try {
        this.showLoading = true;

        if (this.statusInput && typeof this.statusInput === 'string') {
          this.preorderSetting.config.status = JSON.parse(this.statusInput);
        }

        await preorderApi.updatePreorderSetting(this.preorderSetting.id, this.preorderSetting);
        this.$swal("儲存成功", "", "success");
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存資料錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async fetchProducts() {
      try {
        this.isLoading = true;
        const { data } = await preorderApi.getPreorderSettingProducts(this.preorderSetting.id, {});
        this.products = data.data;
        this.selectedProducts = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    handleUpdateSelectedProducts(products) {
      console.log(products);
      this.products = products;
      this.preorderSetting.config.product_ids = products.map(product => product.id);
    },
    deleteProduct(item, data) {
      this.products.splice(data.index, 1);
      this.preorderSetting.config.product_ids = this.products.map(product => product.id);
      this.doUpdate();
    },
    async upload(e, key) {
      this.isLoading = true
      try {
        let url = await this.uploadFile(e.target.files[0])
        // key 有可能是 xxx.yyy.zzz 的形式，要放到 this.preorderSetting.config 裡面
        const keys = key.split('.');
        let obj = this.preorderSetting.config;
        while (keys.length > 1) {
          const k = keys.shift();
          if (!obj[k]) {
            this.$set(obj, k, {});
          }
          obj = obj[k];
        }
        this.$set(obj, keys[0], url);

        return url
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "上傳失敗", "error");
      } finally {
        this.isLoading = false
      }
    },
    editorInit () {
      require('brace/ext/language_tools') //language extension prerequsite...
      require('brace/mode/json')
      require('brace/mode/html')
      require('brace/theme/chrome')
    },
  }
}
</script>

<style lang="scss" scoped>
.menu-items > li {
  cursor: pointer;

  &.disabled {
    background-color: #ddd;
    opacity: 0.5;
    pointer-events: none;
  }

  .icon-for-done {
    display: none;
  }

  &.done {
    i {
      color: green;
    }

    .icon-for-done {
      display: unset;
    }

    .icon-for-undone {
      display: none;
    }
  }
}
</style>
